// @fileoverview : 영업관리 > 배차

import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { TransportationCreateForm } from "@/features/transportation/form/createForm";
import { TransportationStatementForm } from "@/features/transportation/form/statementForm";
import { TransportationStatusForm } from "@/features/transportation/form/statusForm";
import { TransportationDetailForm } from "@/features/transportation/form/transportationDetailForm";
import { TransportationUpdateForm } from "@/features/transportation/form/updateForm";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { setTransportationStatus, transportationStatusColor } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { ActionIcon, Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  ScmApiTransportationTransportationIdDeleteRequest,
  TransportationGet200Response,
  TransportationGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconEdit,
  IconFilterPlus,
  IconPlus,
  IconPrinter,
  IconRecycle,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const TransportationPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, setSelectedRows, refetch, resetQueryStrings } = useStandardLayout();
  const { t } = useTranslation();

  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiTransportationTransportationIdDeleteRequest) =>
      mutateTransportation
        .delete(params)
        .mutationFn(params as any | ScmApiTransportationTransportationIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert("배차 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: () => {
        customAlert("배차 삭제에 실패하였습니다.", "삭제 실패", "red");
      },
    },
  );

  const columns: readonly Column<TransportationGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
    },
    {
      key: "id",
      name: "배차",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <TransportationDetailForm transportationID={formatterProps.row.id} />,
                null,
                "배차 상세",
              );
            }}
          >
            {setToLocaleString(formatterProps.row.id)}
          </LinkAnchor>
        );
      },
    },
    {
      key: "partnerCode",
      name: "협력사",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                "협력사 상세",
              )
            }
          >
            {formatterProps.row.partnerCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "direction",
      name: "방향",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Badge>{t(formatterProps.row.direction ?? "")}</Badge>;
      },
    },
    {
      key: "status",
      name: "상태",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              transportationStatusColor[
                formatterProps?.row?.status as keyof typeof transportationStatusColor
              ]
            }
          >
            {setTransportationStatus(formatterProps?.row?.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "scheduledAt",
      name: "예정일시",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex>
            {formatterProps.row.scheduledAt ? timeUtil(formatterProps.row.scheduledAt) : ""}
          </Flex>
        );
      },
    },
    // {
    //   key: "quantityOrdered",
    //   name: "차량번호",
    //   sortable: true,
    //   resizable: true,
    //   formatter: (formatterProps) => {
    //     return <>{formatterProps.row.createdAt}</>;
    //   },
    // },
    // {
    //   key: "creatorUserCode",
    //   name: "적재품목 갯수",
    //   sortable: true,
    //   resizable: true,
    //   formatter: (formatterProps) => {
    //     return <Flex>{setToLocaleString(formatterProps.row.creatorUserCode)}</Flex>;
    //   },
    // },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateTransportationActionView = () => {
          openModal(
            <TransportationUpdateForm formatterProps={formatterProps?.row} />,
            null,
            `${t("common.edit", { item: "배차" })}`, // 배차 수정
            true,
          );
        };

        const deleteTransportationAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() =>
                deleteMutate({ transportationId: formatterProps?.row.id as number })
              }
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            `${t("common.delete", { item: "배차" })}`, // 배차 삭제
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updateTransportationActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deleteTransportationAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: "배차" })}`,
        `${t("common.deleteFail", { item: "배차" })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ transportationId: JSON.parse(row).id });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: "배차" })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex gap="sm">
          <Button
            leftIcon={<IconPlus />}
            onClick={() => openModal(<TransportationCreateForm />, null, "배차 추가")}
          >
            추가
          </Button>
          <Button
            leftIcon={<IconRefresh />}
            color="orange"
            disabled={!([...selectedRows].length > 0)}
            onClick={() =>
              openModal(
                <TransportationStatusForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "배차 상태 변경",
              ).then((result) => {
                if (result) {
                  // 로직이 정상적으로 처리되면 선택된 행을 초기화
                  setSelectedRows((): Set<any> => new Set());
                }
              })
            }
          >
            상태변경
          </Button>
          <Button
            leftIcon={<IconPrinter />}
            color="orange"
            disabled={
              !(
                [...selectedRows].length === 1 &&
                (JSON.parse([...selectedRows].toString()) as TransportationGet200ResponseRowsInner)
                  ?.direction !== "OUTBOUND"
              )
            }
            onClick={() =>
              openModal(
                <TransportationStatementForm data={JSON.parse([...selectedRows].at(0))} />,
                null,
                "",
              )
            }
          >
            입고명세서 출력
          </Button>
          <Button
            leftIcon={<IconPrinter />}
            color="green"
            disabled={
              !(
                [...selectedRows].length === 1 &&
                (JSON.parse([...selectedRows].toString()) as TransportationGet200ResponseRowsInner)
                  ?.direction !== "INBOUND"
              )
            }
            onClick={() =>
              openModal(
                <TransportationStatementForm data={JSON.parse([...selectedRows].at(0))} />,
                null,
                "",
              )
            }
          >
            출고명세서 출력
          </Button>
        </Flex>
        <Flex gap="sm">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: "배차" })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: "입/출고하는 차량 리스트" })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<TransportationGet200Response, TransportationGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: TransportationGet200ResponseRowsInner) => JSON.stringify(row)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<TransportationGet200Response> />
      </Flex>
    </Flex>
  );
};
